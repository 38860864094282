import { render, staticRenderFns } from "./otherLogin.vue?vue&type=template&id=ac8b2bac&scoped=true&"
import script from "./otherLogin.vue?vue&type=script&lang=js&"
export * from "./otherLogin.vue?vue&type=script&lang=js&"
import style0 from "./otherLogin.vue?vue&type=style&index=0&id=ac8b2bac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac8b2bac",
  null
  
)

export default component.exports