<template>
  <div class="product-container">
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      custom-class="quanshiDialog"
      :append-to-body="true"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <el-card class="box-card">
        <p style="width: 100%;padding: 50px 0; text-align: center;">您的信息注册失败，请联系相关代表</p>
      </el-card>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>
<script>
import indexApi from "@/api";
import { setTimeout } from 'timers';
let loading;
export default {
  name: "otherLogin",
  data(){
    return {
      fullscreenLoading: false,
      centerDialogVisible: false,
      show: false,
    }
  },
  created() {
    let that = this;
    console.log("微信登录",)
    const {success,registerType,token,errorMessage} = that.$route.query;
    console.log(success,registerType,token,errorMessage);
    loading = that.$loading({
      lock: true,
      text: '正在处理，请稍后',
      spinner: 'el-icon-loading',
      background: 'rgba(0,0,0,.5)',
      customClass: "quanshiLoading",
    });
    switch (success){
      case "true":
        if(registerType == "0") { //用户未注册 直接return出去 去注册页面
          const {href} = that.$router.resolve({name: "register"});
          console.log("href",href)
          return location.replace(href);
        }
        localStorage.setItem("token",token);
        that.getUserInfo();
        break;
      case "false":
        alert(`接口请求失败${errorMessage}`);
        break;
    }
  },
  methods:{
    // 个人中心数据
    getUserInfo() {
      let that = this;
      let params = {type: 0};
      indexApi.getUserInfo(params).then(
        res => {
          console.log('调用了获取用户信息接口：', res.data);
          // return
          if(res.success){
              loading.close();
              that.$point.userIdentity(res,that,(data)=>{
                that.$store.commit('setUserName', data.name);
                that.$store.commit('setUserAvatar', data.avatar);
                loading.close();
                if(that.$route.query.callBackUrl){ //全时
                  // alert(this.$route.query.callBackUrl)
                  // let token = res.data && res.data.token;
                  that.$stroage.setLocalItem('token', localStorage.getItem('token'));
                  const {href} = that.$router.resolve({name: "Quanshi"});
                  let loginPath = `${href}?callbackUrl=${that.$route.query.callBackUrl}`;
                  console.log("loginPath", loginPath)
                  localStorage.setItem('fakeLogin', true);
                  location.replace(loginPath);
                  return;
                }
                localStorage.setItem('fakeLogin', false);
                if (data && data.tagStatus === 1) { //1进入选择标签页
                  // that.$router.push({name: 'registerNext'});
                  const {href} = that.$router.resolve({name: "registerNext"});
                  console.log("registerNext-href",href)
                  return location.replace(href);
                } else if (data && data.tagStatus === 0) { //0跳过选择标签页，直接进入首页
                  // that.$router.push({name: 'home'});
                  const {href} = that.$router.resolve({name: "Home"});
                  console.log("home-href",href)
                  return location.replace(href);
                }
                localStorage.setItem("fakeLogin", false)
              });
          } else {
            that.$message.error(res.message);
          }
          // if (res && res.code === 1000) {
          //   console.log("res.data.isAppeal === 1;:",res.data.isPrivacy,res.data.state);
          //   that.$point.userIdentity(res,that,(data)=>{
          //     console.log(data,"000000000000000000000000000000000000000000000000000")
          //     // sessionStorage.setItem("qsUserInfo",data)
          //     // that.userInfo = data;
          //     // that.$store.commit('setUserName', data.name);
          //     // that.$store.commit('setUserAvatar', data.avatar);
          //   });
          // } else {
          //   that.$message.error(res.message);
          // }
        },
        err => {}
      );
    },
  }
}
</script>

<style scoped lang="scss">
.product-container {
  background-image: url('~./images/bg.jpg');
  background-size: cover;
  line-height: 1;
  position: relative;
}
</style>
